import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout, LayoutPanel, SearchBox} from 'rc-easyui';
import { Utils } from "../Vector/Utils";
import { DateUtils } from "../Vector/DateUtils";
import { KepuContent } from "./KepuContent";
import '../custom.css';
import './warnlist.css';

export class KepuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchingValue: {
                value: '大风',
                category: null
            },
            categories: [
                { value: 'all', text: '关键词', iconCls: 'icon-filter' }
            ],
            senderCode: "530100000000",
            severity: "",
            eventType: "",
            listcont: 0,
            warndata: [],
            senderdata: [],
            severitydata: [],
            eventdata: [],
            eventicon: "more",
            severityicon: "more",
            sendericon: "more",
            eventdispaly: true,
            severitydispaly: true,
            senderdispaly: true,
            eventname: "预警种类",
            severityname: "预警等级",
            sendername: "发布单位",
            tableRowData: []
        }
    }

    componentDidMount() {
        document.title = "预警科普";
        this.queryArticlesData();
        window.addEventListener('resize',this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        // 基准大小
        var baseSize = 32;
        // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
        var scale = document.documentElement.clientWidth / 750;
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';

    }

    divWarnListItemHandleClick(warnitem) {
        //alert(id);
        this.props.history.push({ pathname: '/kepucontent', query: { article:warnitem}});
    }

    formatDate(v) {
        let vt = Date.parse(v);
        let strDv = "-";
        if (vt > 0) {
            let dv = new Date(vt);
            strDv = DateUtils.FormatDate("YYYY-mm-dd HH:MM", dv);
        }
        return (<span>{strDv}</span>);
    }

    formatImgUrl(v) {
        if (v == null || v.length < 1)
            v = "https://www.yn12379.cn/img/kpjy.png";
        return v;
    }

    queryArticlesData() {
        let tempData = {
            keyWord: ""
        };
        Utils.DoFetch("/Warn/QueryArticlesData", JSON.stringify(tempData), (result) => {
            this.setState({ warndata: result.rows });
        });
    }

    handleSearch(searchingValue) {
        let tempData;
        switch (searchingValue.category) {
            case "all":
                tempData = {
                    keyWord: searchingValue.value
                };
                break;
            default:
                return null;
        }
        Utils.DoFetch("/Warn/QueryArticlesData", JSON.stringify(tempData), (result) => {
            this.setState({ warndata: result.rows });
        });
        this.setState({ searchingValue: searchingValue });
    }
    handleClear() {
        const value = Object.assign({}, this.state.searchingValue, { value: null });
        this.setState({ searchingValue: value });
    }
    render() {
        
       
       
        return (
            <Layout style={{ width: "100%", height: "100%" }}>
                <Route path='/kepucontent' component={KepuContent} />                
                <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                    <Layout style={{ width: "100%", height: "100%" }}>                        
                        <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                            <Layout style={{ width: "100%", height: "100%" }}>
                                <LayoutPanel border={false} region="north" style={{ height: 40 }}>
                                    <SearchBox
                                        style={{ width: "95%" }}
                                        placeholder="搜索内容"
                                        value={this.state.searchingValue.value}
                                        category={this.state.searchingValue.category}
                                        categories={this.state.categories}
                                        onSearch={this.handleSearch.bind(this)}
                                        addonRight={() => (
                                            <span className="textbox-icon icon-clear" title="Clear value" onClick={this.handleClear.bind(this)}></span>
                                        )}
                                    />
                                </LayoutPanel>
                                <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                                    {
                                        this.state.warndata.length > 0 ?
                                            <div>
                                                {
                                                    this.state.warndata.map(warn => {
                                                        return (
                                                            <div>
                                                                <div onClick={() => this.divWarnListItemHandleClick(warn)} class='alarmTitle' id={'alarm_' + warn.Id} style={{ paddingLeft: '10px' }}>
                                                                    <table align='left' height='50px' >
                                                                        <tr>
                                                                            <td style={{ paddingRight: '0px', width: '70px' }} rowSpan='2'><img src={this.formatImgUrl(warn.img_url)} height='60px' width='60px' /></td>
                                                                            <td style={{ fontSize: '15px', fontWeight: 600, color: '#333', paddingLeft: '0px' }}>{warn.title}</td>
                                                                        </tr>
                                                                        <tr >
                                                                            <td style={{ color: '#A9A9A9', fontSize: '10px', fontWeight: 100 }} >{this.formatDate(warn.add_time)}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div style={{ width: "90%", borderBottom: '1px #cdcdcd solid', marginTop: '10px', marginLeft: "5px" }}></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div class='currentAlarmMsg1'><img src={process.env.PUBLIC_URL + '/img/warnlist/symbol/dqwyj.png'} /></div>
                                    }
                                </LayoutPanel>
                            </Layout>
                        </LayoutPanel>
                    </Layout>
                </LayoutPanel>
            </Layout>
        );
    }
}