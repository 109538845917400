import React, { Component } from 'react';
import { render } from 'react-dom';
import { Messager } from 'rc-easyui';
import '../ubase.css';
import '../uustyles.css';
import { Utils } from '../Vector/Utils';


export class WarnRss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setStep: WarnRss.Step_First,

            cityData: [
                { "AREANAME": "昆明", "AREACODE": "530100000000", AdmLevel: 2 }
            ],
            searchedCities:[],
            eventTypeData: [
                { "EVENTNAME": "全选", "EVENTTYPE": "0000" },
                { "EVENTNAME": "台风", "EVENTTYPE": "01" },
                { "EVENTNAME": "暴雨", "EVENTTYPE": "02" },
                { "EVENTNAME": "寒潮", "EVENTTYPE": "03" },
                { "EVENTNAME": "大风", "EVENTTYPE": "11B06" },
                { "EVENTNAME": "沙尘暴", "EVENTTYPE": "05" },
                { "EVENTNAME": "高温", "EVENTTYPE": "06" },
                { "EVENTNAME": "干旱", "EVENTTYPE": "07" },
                { "EVENTNAME": "雷电", "EVENTTYPE": "11B14" },
                { "EVENTNAME": "冰雹", "EVENTTYPE": "09" },
                { "EVENTNAME": "霜冻", "EVENTTYPE": "10" },
                { "EVENTNAME": "大雾", "EVENTTYPE": "11" },
                { "EVENTNAME": "霾", "EVENTTYPE": "12" },
                { "EVENTNAME": "雷雨大风", "EVENTTYPE": "13" }
            ],
            severityData: [
                { "ServerityName": "全选", "ServerityCode": "ALL" },
                { "ServerityName": "蓝", "ServerityCode": "BLUE" },
                { "ServerityName": "黄", "ServerityCode": "YELLOW" },
                { "ServerityName": "橙", "ServerityCode": "ORANGE" },
                { "ServerityName": "红", "ServerityCode": "RED" }
            ],
            user: {
                openid: "",
                nickname: "",
                headimgurl: ""
            },
            userRss:[],
            //userRss: [{
            //    cityCode: "530400000000",
            //    eventType: {
            //        types: [
            //            "11B06"
            //        ],
            //        severities: [
            //            "YELLOW"
            //        ],
            //        night: true
            //    }
            //}, {
            //    cityCode: "530124000000",
            //    eventType: {
            //        types: [
            //            "11B06"
            //        ],
            //        severities: [
            //            "BLUE"
            //        ],
            //        night: false
            //    }
            //}],
            //currEditRss: {
            //    cityCode: "",
            //    eventType: {
            //        types: [],
            //        severities: ["RED"],
            //        night: false
            //    }
            //},
            currEditRssIndex: -1
        };
        //{process.env.PUBLIC_URL + "img/ucenter/dy-mine.png"}
        let param = this.props.location.search;
        let openid = "";
        if (param !== "") {
            openid = param.replace("?oid=", "");
        }
        this.getWXUserInfo(openid);
    }    
    static Step_First = 1;
    static Step_SelectCity = 2;
    static Step_SelectWarnType = 3;
    static AdmLevel_City = 3;//地级市
    static AdmLevel_County = 4;//县级市
    static EventTypeCode_All = "00000";
    static SeverityCode_All = "ALL";
    getWXUserInfo(oid) {
        let data = {
            openid:oid
        };
        Utils.DoFetch("/UC/QueryCurrentWXOperInfo", JSON.stringify(data), result => {
            //alert(JSON.stringify(result));
            this.setState({
                user: result.user,
                cityData: result.cityData,
                userRss: result.userRss,
                eventTypeData: result.eventTypeData
            });
        });
    }
    componentDidMount() {
        document.title = "预警订阅";
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        var baseSize = 32;
        var scale = document.documentElement.clientWidth / 750;
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';
    }

    onAddRssClick() {
        if (this.state.userRss.length >= 3) {
            this.msgAlert.alert({
                title: "提示",
                icon: "info",
                msg: "用户最多只能添加三个定制城市!"
            });
            return;
        }
        this.setState({
            searchedCities:[],
            setStep: WarnRss.Step_SelectCity,
            currEditRss: {
                cityCode: "",
                eventType: {
                    types: [],
                    severities: ["RED"],
                    night: false
                }
            },
            currEditRssIndex: -1
        });
    }
    onCompliteRssClick() {
        if (this.state.currEditRss.eventType.types.length == 0 || this.state.currEditRss.eventType.severities.length == 0) {
            this.msgAlert.alert({
                title: "提示",
                icon: "info",
                msg: "请选择要订阅的预警级别以及等级信息!"
            });
            return;
        }
       
        
        let userRss = this.state.userRss;
        if (this.state.currEditRssIndex == -1) {
            userRss.push(this.state.currEditRss);
            this.setState({
                userRss: userRss,
                setStep: WarnRss.Step_First
            });
        } else {
            userRss[this.state.currEditRssIndex] = this.state.currEditRss;
            this.setState({
                userRss: userRss,
                setStep: WarnRss.Step_First
            });
        }
        let saveData = {
            openid: this.state.user.openid,
            userRss: userRss
        }
        Utils.DoFetch("UC/SaveCurrentEXOperRssInfo", JSON.stringify(saveData), result => {
            if (result.result != 1) {
                this.msgAlert.alert({
                    title: "提示",
                    icon: "error",
                    msg: result.info
                });
            }
        });
    }

    onStepClick(nextStep) {
        let searchedCities = this.state.searchedCities;
        if (nextStep == WarnRss.Step_SelectWarnType) {
            if (this.state.currEditRss.cityCode === "") {
                this.msgAlert.alert({
                    title: "提示",
                    icon: "info",
                    msg: "请选择一个城市!"
                });
                return;
            }
        } else if (nextStep == WarnRss.Step_SelectCity) {
            searchedCities = [];
        }
        this.setState({
            searchedCities: searchedCities,
            setStep: nextStep
        });
    }
    getOneRss(rss, index) {
        let cityName = "";
        let cityData = this.state.cityData;
        for (let i = 0; i < cityData.length; i++) {
            if (cityData[i].AREACODE == rss.cityCode) {
                cityName = cityData[i].AREANAME;
                break;
            }
        }
        let events = [];
        let eventTypeData = this.state.eventTypeData;
        for (let i = 0; i < rss.eventType.types.length; i++) {
            let typeCode = rss.eventType.types[i];
            if (typeCode === WarnRss.EventTypeCode_All) {
                continue;
            }
            let typeIndex = -1;
            for (let j = 0; j < eventTypeData.length; j++) {
                if (typeCode == eventTypeData[j].EVENTTYPE) {
                    typeIndex = j;
                    break;
                }
            }
            if (typeIndex >= 0) {
                events.push(<li code={eventTypeData[typeIndex].EVENTTYPE} > {eventTypeData[typeIndex].EVENTNAME}</ li>);
            }
        }
        return (<>
            <li className="dy-mine-item" index={index}>
                <div className="dy-mine-content">
                    <h4 className="dy-mine-cite" code={rss.cityCode}>{cityName}</h4>
                    <ul className="dy-mine-types">
                        {events}
                    </ul>
                </div>
                <div className="dy-mine-func">
                    <img src={process.env.PUBLIC_URL + "img/ucenter/dy-edit.png?1"} alt="" className="dy-edit" onClick={() => this.editOneRss(index)} />
                    <img src={process.env.PUBLIC_URL + "img/ucenter/dy-delete.png?1"} alt="" className="dy-delete" onClick={() => this.deleteOneRss(index)}/>
                </div>
            </li>
        </>)
    }
    getUserRss() {
        let rsses = [];
        for (let i = 0; i < this.state.userRss.length; i++) {
            let oneRss = this.state.userRss[i];
            rsses.push(this.getOneRss(oneRss,i));
        }
        return rsses;
    }
    editOneRss(index) {
        this.setState({
            searchedCities:[],
            currEditRss: this.state.userRss[index],
            setStep: WarnRss.Step_SelectCity,
            currEditRssIndex: index
        });
    }
    deleteOneRss(index) {
        let userRss = this.state.userRss;
        userRss.splice(index, 1);
        this.setState({
            userRss: userRss
        });
        let saveData = {
            openid: this.state.user.openid,
            userRss: userRss
        }
        Utils.DoFetch("UC/SaveCurrentEXOperRssInfo", JSON.stringify(saveData), result => {
            if (result.result != 1) {
                this.msgAlert.alert({
                    title: "提示",
                    icon: "error",
                    msg: result.info
                });
            }
        });
    }
    onCityClick = (cityCode) => {
        let currEditRss = this.state.currEditRss;
        currEditRss.cityCode = cityCode;
        this.setState({
            currEditRss: currEditRss
        });
    }
    isInRssCities(city) {
        let isIn = false;
        this.state.userRss.forEach((rss) => {
            if (rss.cityCode == city.AREACODE) {
                isIn = true;
            }
        });
        return isIn;
    }
    handleSearchCities(event) {
        let ss = event.target.value;
        if (ss === "" || ss === undefined) {
            this.setState({
                searchedCities: []
            });
        } else {
            let searchedCities = [];
            this.state.cityData.forEach((city) => {
                if (city.AdmLevel === WarnRss.AdmLevel_County) {
                    //searchedCities.push(city);
                    if (city.AREANAME.indexOf(ss) >= 0) {
                        searchedCities.push(city);
                        
                    }
                }
            });
            if (searchedCities.length === 0) {
                if (this.state.searchedCities.length !== 0) {
                    this.setState({
                        searchedCities: []
                    })
                }
            } else {
                this.setState({
                    searchedCities: searchedCities
                });
            }
        }
    }
    getAllSearchedCityContent() {
        let cityContents = [];
        let currRssCitypeCode = this.state.currEditRss.cityCode;
        if (this.state.searchedCities != undefined) {
            this.state.searchedCities.forEach((city) => {
                if (city.AREACODE != currRssCitypeCode) {
                    cityContents.push(
                        <li className="dy-city-item " onClick={this.onCityClick.bind(this, city.AREACODE)} > {city.AREANAME}</li >
                    );
                }
            })
        }
        return cityContents;
    }
    getAllCityContent() {
        let cityContents = [];
        
        let cityData = this.state.cityData;
        let currRssCitypeCode = this.state.currEditRss.cityCode;
        
        let isInCities = false;//是不是地级市
        for (let i = 0; i < cityData.length; i++) {
            let city = cityData[i];
            if (city.AREACODE !== currRssCitypeCode) {
                //如果以及存在其他选项，这里就不要出现了
                let hasSet = false;
                for (let j = 0; j < this.state.userRss.length; j++) {
                    if (this.state.userRss[j].cityCode === city.AREACODE) {
                        hasSet = true;
                        break;
                    }
                }
                if (hasSet) {
                    continue;
                }
            }
            if (city.AdmLevel == WarnRss.AdmLevel_City) {
                let liCityClassName = "dy-city-item";
                if (city.AREACODE == currRssCitypeCode) {
                    liCityClassName = "dy-city-item  item-selected";
                    isInCities = true;
                } 
                cityContents.push(
                    <li className={liCityClassName} data-areaid={city.cityCode} onClick={this.onCityClick.bind(this, city.AREACODE)}>{city.AREANAME}</li>
                );
            }
        }
        if (!isInCities) {
            //有可能再县城里
            for (let i = 0; i < cityData.length; i++) {
                let city = cityData[i];
                if (city.AdmLevel == WarnRss.AdmLevel_County) {
                    if (city.AREACODE == currRssCitypeCode) {
                        cityContents.push(
                            <li className="dy-city-item  item-selected" data-areaid={city.cityCode} onClick={this.onCityClick.bind(this, city.AREACODE)} > {city.AREANAME}</li >
                        );
                    }
                }
            }
        }
        //alert(this.state.searchedCities);
        
        return cityContents;
    }
    onEventTypeClick(eventCode) {
        let currEditRss = this.state.currEditRss;

        let index = currEditRss.eventType.types.indexOf(eventCode);
        if (index >= 0) {
            currEditRss.eventType.types.splice(index, 1);
            if (eventCode !== WarnRss.EventTypeCode_All) {
                //如果有全选再里面，也要删除
                if (currEditRss.eventType.types[0] === WarnRss.EventTypeCode_All) {
                    currEditRss.eventType.types.splice(0, 1);
                }
            }
        } else {
            if (eventCode === WarnRss.EventTypeCode_All) {
                currEditRss.eventType.types.splice(0, currEditRss.eventType.types.length);
                for (let i = 0; i < this.state.eventTypeData.length; i++) {
                    currEditRss.eventType.types.push(this.state.eventTypeData[i].EVENTTYPE);
                }
            } else {
                currEditRss.eventType.types.push(eventCode);
                if (currEditRss.eventType.types.length == this.state.eventTypeData.length - 1) {
                    currEditRss.eventType.types.splice(0, 0, WarnRss.EventTypeCode_All);
                }
            }
        }
        
        this.setState({
            currEditRss: currEditRss
        });
    }
    getAllEventTypeContent() {
        let eventTypeData = this.state.eventTypeData;
        let events = [];
        let currEditRss = this.state.currEditRss;
        for (let i = 0; i < eventTypeData.length; i++) {
            let eventType = eventTypeData[i];
            
            let evtClassName = "dy-type-item";
            if (currEditRss.eventType.types.indexOf(eventType.EVENTTYPE)>=0) {
                evtClassName = "dy-type-item  item-selected";
            }
            events.push(<li className={evtClassName} onClick={this.onEventTypeClick.bind(this, eventType.EVENTTYPE)} >{eventType.EVENTNAME}</li>)
        }
        return events;
    }
    onSeverityClick(severityCode) {
        let currEditRss = this.state.currEditRss;

        let index = currEditRss.eventType.severities.indexOf(severityCode);
        if (index >= 0) {
            currEditRss.eventType.severities.splice(index, 1);
            if (severityCode !== WarnRss.SeverityCode_All) {
                //如果有全选再里面，也要删除
                if (currEditRss.eventType.severities[0] === WarnRss.SeverityCode_All) {
                    currEditRss.eventType.severities.splice(0, 1);
                }
            }
        } else {
            if (severityCode === WarnRss.SeverityCode_All) {
                currEditRss.eventType.severities.splice(0, currEditRss.eventType.severities.length);
                for (let i = 0; i < this.state.severityData.length; i++) {
                    currEditRss.eventType.severities.push(this.state.severityData[i].ServerityCode);
                }
            } else {
                currEditRss.eventType.severities.push(severityCode);
                if (currEditRss.eventType.severities.length == this.state.severityData.length - 1) {
                    currEditRss.eventType.severities.splice(0, 0, WarnRss.SeverityCode_All);
                }
            }
        }

        this.setState({
            currEditRss: currEditRss
        });
    }
    getAllSeverityContent() {
        let severityData = this.state.severityData;
        let contents = [];
        let currEditRss = this.state.currEditRss;
        for (let i = 0; i < severityData.length; i++) {
            let serv = severityData[i];
            let evtClassName = "dy-color-item";
            let index = currEditRss.eventType.severities.indexOf(serv.ServerityCode);
            if (index >= 0) {
                evtClassName = evtClassName + " item-selected";
            }
            contents.push(<li className={evtClassName} onClick={this.onSeverityClick.bind(this, serv.ServerityCode)}>{serv.ServerityName}</li>);
        }
        return contents;
    }
    handleNightChangded(evt) {
        //alert(evt.target.checked);
        let currEditRss = this.state.currEditRss;
        currEditRss.eventType.night = evt.target.checked;
        this.setState({
            currEditRss: currEditRss
        })
    }
    getStepContent() {
        switch (this.state.setStep) {
            case WarnRss.Step_First:
                return (<div ref="div_addset" className="dy-wrapper-mine">
                    <div className="dy-header">
                        <img style={{ borderRadius: "100%" }} src={this.state.user.headimgurl} alt="" />
                        <h3>{this.state.user.nickname}的定制</h3>
                    </div>
                    <div className="dy-mine">
                        <ul className="dy-mine-list">
                            {
                                this.getUserRss()
                            }         
                        </ul>
                    </div>
                    <div className="dy-footer">
                        <div className="dy-btn btn-add" onClick={() => this.onAddRssClick()} >添加定制 </div>
                    </div>
                </div>);
            case WarnRss.Step_SelectCity:
                return (<div className="dy-wrapper-city">
                    <div className="dy-header">
                        <img className="dy-header_img" src={process.env.PUBLIC_URL + '/img/ucenter/20190724153155.png'} alt="" />
                    </div>
                    <div className="dy-citys">
                        <div className="dy-search-wrappers">
                            <div><img src={process.env.PUBLIC_URL + "/img/ucenter/icon_yjdy_xzcs.png"} alt="" />
                                            选择订阅城市
                                    </div>                            
                        </div>
                    </div>
                    <div className="dy-city">
                        <div className="dy-search-wrapper">
                            <input type="text" className="dy-search-input" placeholder="搜索县级行政区域" onChange={this.handleSearchCities.bind(this)}/>
                            {/*<img src={process.env.PUBLIC_URL + "/img/ucenter/dy-search.png"} className="dy-search-btn" alt="" onClick={() => this.doSearchClick()} />*/}
                        </div>
                        <ul className="dy-city-list search">
                            {
                                this.getAllSearchedCityContent()
                            }
                        </ul>
                        <ul className="dy-city-list local">
                            {/*<li className="dy-city-item" data-areaid="101010100">昆明</li>*/}
                            {/*<li className="dy-city-item item-selected" data-areaid="101020100">玉溪</li>*/}
                            {
                                this.getAllCityContent()
                                
                            }
                        </ul>
                    </div>
                    <div className="dy-footers">
                        <div className="dy-btn btn-city" onClick={() => this.onStepClick(WarnRss.Step_SelectWarnType)} >下一步</div>
                    </div>
                </div>);
            case WarnRss.Step_SelectWarnType:
                return (<div className="dy-wrapper-type">
                    <div className="dy-types">
                        <div style={{ width: '90%' }}><img style={{ width: 40 }} src={process.env.PUBLIC_URL + "/img/ucenter/icon_yjdy_yjlx.png"} alt="" />
                                      选择预警类型
                                </div>
                        {/*<div className="dv_h3"><h3><b>选择预警类型</b></h3></div>*/}
                    </div>

                    <div className="dy-type">
                        <ul className="dy-type-list">
                            {this.getAllEventTypeContent()}
                        </ul>
                    </div>

                    <div className="dy-types">
                        <div style={{ width: '90%' }}><img style={{ width: 40 }} src={process.env.PUBLIC_URL + "/img/ucenter/icon_yjdy_xzcs.png"} alt="" />接收预警级别</div>
                        {/*<div className="dv_h3"><h3><b>接收预警级别</b></h3></div>*/}
                    </div>
                    <div className="dy-typess">
                        <ul className="dy-type-list">
                            {
                                this.getAllSeverityContent()
                            }

                        </ul>
                    </div>
                    <div className="dy-types">
                        <div style={{ width: '90%' }}><img style={{ width: 40 }} src={process.env.PUBLIC_URL + "/img/ucenter/icon_yjdy_jssj.png"} alt="" /><b>夜间免打扰</b>(23:00-5:30) </div>
                        {/*<div className="dv_h3"><h3><b>夜间免打扰</b>(23:00-5:30)</h3></div>*/}
                        <label><input id="btn-switch"
                            className="btn-switch large" type="checkbox"
                            value="1" checked={this.state.currEditRss.eventType.night}
                            onChange={(evt) => this.handleNightChangded(evt)}
                        /></label>
                       
                    </div>
                    <div className="dy-footerss">
                        <div className="dy-btn btn-return" onClick={() => this.onStepClick(WarnRss.Step_SelectCity)}>上一步</div>
                        <div className="dy-btn btn-type" onClick={() => this.onCompliteRssClick()}>完成</div>
                    </div>
                </div>);
        }
    }
    render() {
        return (
            <div className="dy-wrapper">
                {
                    this.getStepContent()
                }
                < Messager ref={ref => this.msgAlert = ref} ></Messager >
            </div>
        );
    }

}