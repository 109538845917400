import React, { Component } from 'react';
import { Utils } from '../Vector/Utils';

export class DouYin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [
                {
                    Id: 1,
                    PicUrl: "",
                    VideoUrl: ""
                }
            ]
        };
        this.getDouyinVideos();
    }
    getDouyinVideos() {
        //QueryDouyinVideos
        let temp = {

        };
        Utils.DoFetch("/Warn/QueryDouyinVideos", temp, (result) => {
            this.setState({
                videos: result.rows
            })
        });
    }
    renderOneVideo(index) {
        let v = this.state.videos[index];
        return (
            <a href={v.VideoUrl} style={{ width: "33%", textAlign:"cnter" }}><img width="95%" src={v.PicUrl}></img></a>
        );
    }
    renderOneRow(startIndex) {
        let dyvis = [];
        for (let i = startIndex; i < startIndex + 3; i++) {
            if (i >= this.state.videos.length)
                break;
            dyvis.push(this.renderOneVideo(i));
        }
        let onrow =
            <div className="f-row" style={{
                
                width: "100%"
            }}>
                {
                    dyvis
                }
            </div>;
        return (onrow);
    }
    renderVideos() {
        let videos = this.state.videos;
        let vrows = parseInt((videos.length + 2) / 3);
        let vcontent = [];
        for (let i = 0; i < vrows; i++) {
            vcontent.push(this.renderOneRow(i * 3));
            vcontent.push(<div className="f-row" style={{ backgroundColor: "#070C25",height: 10 }}></div>);
        }
        return (vcontent);
    }
    render() {
        return (
            <div className="f-full" style={{ backgroundColor: "#070C25"}}>
                <div className="f-row" style={{
                    width: "100%",
                    height:"2rem"
                }}>
                    <img src={process.env.PUBLIC_URL + '/img/img_yjdy_banner.png'} width="100%" height="100%" />
                    <img src={process.env.PUBLIC_URL + '/img/img_yjdy_logo_yn.png'}
                        
                        style={{
                            zIndex:1000,
                            position: 'absolute',
                            left: "0.0375rem",
                            bottom: "-0.08rem",
                            height: "0.65625rem",
                            width: "0.65625rem"
                        }}
                    />
                </div>
                <div className="f-row" style={{
                   
                    width: "100%"
                }}>
                     <div className="f-full">
                    <div className="f-row" style={{
                        width: "100%", verticalAlign:"bottom" }}>
                        <span style={{ color: "white",fontSize: "0.2625rem" }}>云南预警发布</span>

                        <span style={{ fontSize: "0.2625rem", color: "#3672CD" }}>（抖音号：1168029346）</span>
                    </div>
                    <div className="f-row" style={{ backgroundColor: "#070C25", height: 10 }}></div>
                    <div className="f-row" style={{
                        width: "100%", verticalAlign: "bottom"
                    }}>
                        <span style={{ color: "white", fontSize: "0.1rem" }}>国家预警信息发布中心旗下账号</span>

                        </div>
                    </div>
                </div>
                <div className="f-row" style={{  height: 10 }}></div>
                {
                    this.renderVideos()
                }
                
            </div>
        );
    }
}