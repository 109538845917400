export class Utils {

    static DoFetch(url, bodyData, callback) {

        fetch(url, {
            body: bodyData,
            method: "POST"
        }).then(
            res => {
                return res.json()
            }
        ).then(
            data => {
                callback(data);
            }
        ).catch(
            e => alert(url + " " + e)
        );

    }
    static DoFetchText(url, bodyData, callback) {

        fetch(url, {
            body: bodyData,
            method: "POST"
        }).then(
            res => {
                return res.text()
            }
        ).then(
            data => {
                callback(data);
            }
        ).catch(
            e => alert(url + " " + e)
        );

    }
    static DoFetchGetText(url, callback) {
        fetch(url).then(
            res => {

                return res.text();
            }
        ).then(
            data => {
                callback(data);
            }
        ).catch(e => alert(e));

    }

    // 获取元素相对于页面文档的位置----------------------------------------------
    static getElementPagePos = function (el) {
        if (el.parentNode === null || el.style.display == 'none') { return false; }
        var parent = null;
        var pos = [];
        var box;
        if (el.getBoundingClientRect) {     //IE
            box = el.getBoundingClientRect();
            var scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
            var scrollLeft = Math.max(document.documentElement.scrollLeft, document.body.scrollLeft);
            return { x: box.left + scrollLeft, y: box.top + scrollTop };

        } else {
            pos = [el.offsetLeft, el.offsetTop];
            parent = el.offsetParent;
            if (parent != el) {
                while (parent) {
                    pos[0] += parent.offsetLeft;
                    pos[1] += parent.offsetTop;
                    parent = parent.offsetParent;
                }
            }
        }
        return { x: pos[0], y: pos[1] };
    }


    static NumAdd(arg1, arg2) {
        var r1, r2, m;

        try {

            r1 = arg1.toString().split(".")[1].length;

        } catch (e) {

            r1 = 0;

        }

        try {

            r2 = arg2.toString().split(".")[1].length;

        } catch (e) {

            r2 = 0;

        }

        m = Math.pow(10, Math.max(r1, r2));

        return (arg1 * m + arg2 * m) / m;
    }
    static NumSub(arg1, arg2) {
        var r1, r2, m, n;

        try {

            r1 = arg1.toString().split(".")[1].length;

        } catch (e) {

            r1 = 0;

        }

        try {

            r2 = arg2.toString().split(".")[1].length;

        } catch (e) {

            r2 = 0;

        }

        m = Math.pow(10, Math.max(r1, r2)); //动态控制精度长度            

        n = (r1 >= r2) ? r1 : r2;

        return parseFloat(((arg1 * m - arg2 * m) / m).toFixed(n));
    }
    static NumMul(arg1, arg2) {
        var m = 0,

            s1 = arg1.toString(),

            s2 = arg2.toString();

        try {

            m += s1.split(".")[1].length;

        } catch (e) { }

        try {

            m += s2.split(".")[1].length;

        } catch (e) { }

        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    }
    static NumDiv(arg1, arg2) {

        var t1 = 0,

            t2 = 0,

            r1, r2;

        try {

            t1 = arg1.toString().split(".")[1].length;

        } catch (e) { }

        try {

            t2 = arg2.toString().split(".")[1].length;

        } catch (e) { }



        r1 = Number(arg1.toString().replace(".", ""));

        r2 = Number(arg2.toString().replace(".", ""));

        return (r1 / r2) * Math.pow(10, t2 - t1);



    }
}