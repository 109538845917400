import React, { Component } from 'react';
import { Route } from 'react-router';
import Script from 'react-load-script';
import { Layout, LayoutPanel, DataGrid, Messager, GridColumn, LinkButton, TextBox } from 'rc-easyui';
import { WarnMapTopInfo } from "./WarnMap.TopInfo";
import { Utils } from "../Vector/Utils";
import './warnmap.css';
import { DateUtils } from '../Vector/DateUtils';

window.gdMapClick1 = function (id) {

    window.location.query = { warn: id };
    window.location.href = "/warncontent?id="+id;
    //wmap.outMapClick();
}
export class WarnMap extends Component {
//var WarnMap = React.createClass({
    constructor(props) {
        super(props);
        this.state = {
            viewSelIcons: false,
            iconViews: {
                blue: true,
                blueUrl: process.env.PUBLIC_URL + '/img/warnlist/blue.png',
                yellow: true,
                yellowUrl: process.env.PUBLIC_URL + '/img/warnlist/yellow.png',
                orange: true,
                orangeUrl: process.env.PUBLIC_URL + '/img/warnlist/orange.png',
                red: true,
                redUrl: process.env.PUBLIC_URL + '/img/warnlist/red.png',
            },
            alevel: {
                left: 100,
                bkcolor: "navy",
                color: "white",
                seled:true
            },
            atype: {
                left: 200,
                bkcolor: "#eee",
                color: "333",
                seled:false
            },
            windowHeight:532,
            mapHeight:500,
            scriptStatus: 'no',
            centerLng: 101.586,
            centerLat: 24.8585,
            makersLevel: [
                {
                    lng: 101.39722,
                    lat: 24.9096045,
                    //icon: '/img/warnlist/symbol/11B05_blue.png',
                    iconType: '/img/warnlist/icon_map_bl.png',
                    guideContent:"",
                    SEVERITY: 'BLUE',
                    EVENTTYPE: "11B05",
                    DESCRIPTION:"",
                    HEADLINE: "蓝色预警信息蓝色预警信息蓝色预警信息蓝色预警信息",
                    SENDTIME:"2020-5-10 10:0:0",
                    id:1
                }
            ]
            
        };
        this.map = null;
        this.gdMarks = [];
        
    }
    componentDidMount() {
        this.handleWindowSize();
        window.addEventListener('resize', this.handleWindowSize);
        this.queryWarn();
    }
    componentWillUnmount() {
     
        window.removeEventListener('resize', this.handleWindowSize);
    }
    queryWarn() {
        let tempData = {
            code:""//暂时没有
        };
        Utils.DoFetch("/Map/QueryAllWarnInfo", JSON.stringify(tempData), result => {
            this.setState({
                makersLevel: result.data
            });
            if (this.map != null) {
                this.markAll(result.data);
            }
        });
    }
    handleWindowSize = () => {
        //alert(e.target.innerWidth + ':' + e.target.innerHeight)
        //alert(window.innerWidth + ":" + window.innerHeight);
        //alert(this.myRef.current.clientHeight);
        let winCenter = window.innerWidth / 2;
        let alevel = this.state.alevel;
        alevel.left = winCenter - 100;
        let atype = this.state.atype;
        atype.left = winCenter;
        this.setState({
            alevel: alevel,
            atype:atype,
            windowHeight: window.innerHeight,
            mapHeight: window.innerHeight-32
        });
    }
    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true, scriptStatus: 'yes' })
        
        this.initGDMap();
    }
    markAll(makers) {
        let AMap = window.AMap;
        //let makers = this.state.makersLevel;
        let ow = 224;
        let oh = 190;
        let sw = 32;
        let sh = 30;
        if (!this.state.alevel.seled) {
            //makers = this.state.makersType;
            ow = 24;
            oh = 24;
            sw = 24;
            sh = 24;
        }
        this.map.remove(this.gdMarks);
        this.gdMarks = [];
        for (let i = 0; i < makers.length; i++) {
            let m = makers[i];
            if (m.SEVERITY == "BLUE") {
                if (!this.state.iconViews.blue) {
                    continue;
                }
            }
            if (m.SEVERITY == "YELLOW") {
                if (!this.state.iconViews.yellow) {
                    continue;
                }
            }
            if (m.SEVERITY == "ORANGE") {
                if (!this.state.iconViews.orange) {
                    continue;
                }
            }
            if (m.SEVERITY == "RED") {
                if (!this.state.iconViews.red) {
                    continue;
                }
            }
            let self = this;

            let imgUrl = process.env.PUBLIC_URL + '/img/warnlist/symbol/' + m.EVENTTYPE + '_' + m.SEVERITY + '.png'
            let gdm = new AMap.Marker({
                position: new AMap.LngLat(m.lng, m.lat),
                offset: new AMap.Pixel(-sw / 2, -sh / 2),
                icon: new AMap.Icon({
                    size: new AMap.Size(sw, sh),    // 图标尺寸
                    image: this.state.alevel.seled ? imgUrl : m.iconType,
                    //imageOffset: new AMap.Pixel(-16, -16),  
                    imageSize: new AMap.Size(sw, sh)
                }),
                extData: m.id,
                map: this.map
            });
            AMap.event.addListener(gdm, 'click', function () { self.mapClick(gdm,m);});
            this.gdMarks.push(gdm);
        }
        //alert(JSON.stringify(gbMarks));
        this.map.add(this.gdMarks);
    }
    createInfoWindow(title, content) {
        let info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";

        //可以通过下面的方式修改自定义窗体的宽高
        //info.style.width = "400px";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        //closeX.onclick = closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = 'white';
        middle.innerHTML = content;
        info.appendChild(middle);

        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = 'relative';
        bottom.style.top = '0px';
        bottom.style.margin = '0 auto';
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
    }
    createMapClikWindow(self,content) {
        var clkDiv = document.createElement("div");
        clkDiv.onclick = window.gdMapClick1(self);
        clkDiv.innerHTML = content;
        return clkDiv;
    }
    divWarnListItemHandleClick(mClick) {
        //alert(id);
        this.props.history.push({ pathname: '/warncontent', query: { warn: mClick } });
    }
    testclick() {

    }
    mapClick(gdm,mClick) {
        //var cache = [];
        //var str = JSON.stringify(evt, function (key, value) {
        //    if (typeof value === 'object' && value !== null) {
        //        if (cache.indexOf(value) !== -1) {
        //            // 移除
        //            return;
        //        }
        //        // 收集所有的值
        //        cache.push(value);
        //    }
        //    return value;
        //});
        //cache = null;
        //console.log(str);
        //let id = gdm.getExtData();
        let id = mClick.id;
        let AMap = window.AMap;
        let imgUrl = process.env.PUBLIC_URL + '/img/warnlist/symbol/' + mClick.EVENTTYPE + '_' + mClick.SEVERITY + '.png'
        let content1 = [
            "<div class=\"warntitle\" onclick=window.gdMapClick1("+mClick.Id + ")><img style=\"float:left;\" src='" + imgUrl + "' height=64 width=60 />",
            "<div style=\"padding:7px 0px 0px 0px;\"><p style=\"font-size:18px;color:black;\">" + mClick.HEADLINE + "</p> ",
            "<p  style=\"font-size:16px;color:gray\">" + this.formatDate(mClick.SENDTIME) + "</p></div> ",
            "</div>"
        ];
        let content = [
            "<div class=\"wpcontenttop\">",
            "<div class=\"warnimg\"><img src=" + imgUrl + " height=64 width=60 alt='test' onerror='" + process.env.PUBLIC_URL + "/img/warnlist/unknow_y.png'\" /></div>",
            "<div class=\"warntitle\"><p><b class=\"normal\">" + mClick.HEADLINE + "</b ></p > <span> " + this.formatDate( mClick.SENDTIME) + "</span ></div >",
            "</div>"
        ];
        let infoWindow = new AMap.InfoWindow({
            isCustom: false,  //使用自定义窗体
            content: content1.join(""),//this.createInfoWindow("title",content.join("<br>")),  //传入 dom 对象，或者 html 字符串
            offset: new AMap.Pixel(0, -16)
        });
        infoWindow.open(this.map, [mClick.lng, mClick.lat]);
        //console.log(JSON.stringify(id + ":" + gdm.getPosition()));
        console.log(id+"");
    }
    formatDate(v) {

        let vt = Date.parse(v);
        let strDv = "-";
        if (vt > 0) {
            let dv = new Date(vt);
            strDv = DateUtils.FormatDate("YYYY-mm-dd HH:MM", dv);
        }
        return strDv;
        //return (<span>{strDv}</span>);
    }
    initGDMap(map) {
        let AMap = window.AMap;
        this.map = new AMap.Map('txmap', {
            resizeEnable: true,
            center: [this.state.centerLng, this.state.centerLat],
            zoom: 6
        });

        this.markAll(this.state.makersLevel);
    }
    
    handleSelICons() {
        let viewIcons = !this.state.viewSelIcons;
        this.setState({
            viewSelIcons: viewIcons
        });
        
    }
    handleSelIBlue() {
        let iconViews = this.state.iconViews;
        iconViews.blue = !iconViews.blue;
        if (!iconViews.blue) {
            iconViews.blueUrl = process.env.PUBLIC_URL + '/img/warnlist/blueseled.png';
        } else {
            iconViews.blueUrl = process.env.PUBLIC_URL + '/img/warnlist/blue.png';
        }
        this.setState({
            iconViews: iconViews
        });
        this.markAll(this.state.makersLevel);
    }
    handleSelIYellow() {
        let iconViews = this.state.iconViews;
        iconViews.yellow = !iconViews.yellow;
        if (!iconViews.yellow) {
            iconViews.yellowUrl = process.env.PUBLIC_URL + '/img/warnlist/yellowseled.png';
        } else {
            iconViews.yellowUrl = process.env.PUBLIC_URL + '/img/warnlist/yellow.png';
        }
        
        this.setState({
            iconViews: iconViews
        });
        this.markAll(this.state.makersLevel);
    }
    handleSelIOrange() {
        let iconViews = this.state.iconViews;
        iconViews.orange = !iconViews.orange;
        if (!iconViews.orange) {
            iconViews.orangeUrl = process.env.PUBLIC_URL + '/img/warnlist/orangeseled.png';
        } else {
            iconViews.orangeUrl = process.env.PUBLIC_URL + '/img/warnlist/orange.png';
        }
        
        this.setState({
            iconViews: iconViews
        });
        this.markAll(this.state.makersLevel);
    }
    handleSelIRed() {
        let iconViews = this.state.iconViews;
        iconViews.red = !iconViews.red;
        if (!iconViews.red) {
            iconViews.redUrl = process.env.PUBLIC_URL + '/img/warnlist/redseled.png';
        } else {
            iconViews.redUrl = process.env.PUBLIC_URL + '/img/warnlist/red.png';
        }
        
        this.setState({
            iconViews: iconViews
        });
        this.markAll(this.state.makersLevel);
    }
    handleSelLevel() {
       
        let alevel = this.state.alevel;
        let atype = this.state.atype;
        if (!alevel.seled) {
            alevel.seled = true;
            alevel.bkcolor = "navy";
            alevel.color = "white";
            atype.seled = false;
            atype.bkcolor = "#eee";
            atype.color = "#222";
            this.setState({
                alevel: alevel,
                atype: atype
            });
            this.markAll(this.state.makersLevel);
        } 
        
    }
    handleSelType() {
        
        let alevel = this.state.alevel;
        let atype = this.state.atype;
        if (!atype.seled) {
            atype.seled = true;
            atype.bkcolor = "navy";
            atype.color = "white";
            alevel.seled = false;
            alevel.bkcolor = "#eee";
            alevel.color = "#222";
            this.setState({
                alevel: alevel,
                atype: atype
            });
            this.markAll(this.state.makersLevel);
        } 
    }
    outMapClick() {
        alert("LOADOK");
    }
    //url="https://map.qq.com/api/gljs?v=1.exp&key=5BGBZ-V5J3X-SK24B-ZD7XC-3KYJ5-LPBNP"
    render() {
        let iconSelUrl = process.env.PUBLIC_URL + '/img/warnlist/selectmap.png';

        return (
            <>
                <Script
                    url="https://webapi.amap.com/maps?v=1.4.15&key=ccdd19a82b5790aa601aa31ede8e5444"
                    onCreate={this.handleScriptCreate.bind(this)}
                    onError={this.handleScriptError.bind(this)}
                    onLoad={this.handleScriptLoad.bind(this)}
                />
               
                <div style={{ width: "100%", height: this.state.windowHeight }}>

                    <WarnMapTopInfo />
                    <div style={{ zIndex: 9999, position: "fixed", width: 48, left: 20, bottom: 100 }}>
                        {
                            this.state.viewSelIcons ?
                                <>
                                    <a onClick={() => this.handleSelIBlue()}><img height={48} width={48} src={this.state.iconViews.blueUrl}></img></a>
                                    <a onClick={() => this.handleSelIYellow()}><img height={48} width={48} src={this.state.iconViews.yellowUrl}></img></a>
                                    <a onClick={() => this.handleSelIOrange()}><img height={48} width={48}  src={this.state.iconViews.orangeUrl}></img></a>
                                    <a onClick={() => this.handleSelIRed()}><img height={48} width={48} src={this.state.iconViews.redUrl}></img></a>
                                </>
                                :
                                null
                        }

                        <a onClick={() => this.handleSelICons()}><img height={48} width={48} src={iconSelUrl}></img></a>
                    </div>
                    <a
                        onClick={() => this.handleSelLevel()}
                        style={
                            {
                                cursor: "hand",
                                borderRadius: "5px 0 0 5px", textAlign: "center",
                                backgroundColor: this.state.alevel.bkcolor,
                                color: this.state.alevel.color,
                                zIndex: 9999, bottom: 10, position: 'fixed', display: "block",
                                left: this.state.alevel.left,
                                fontSize: 16, height: 32, width: 100
                            }
                        }>预警等级</a>
                    <a
                        onClick={() => this.handleSelType()}
                        style={
                            {
                                cursor: "hand",
                                borderRadius: "0 5px 5px 0", textAlign: "center",
                                backgroundColor: this.state.atype.bkcolor,
                                color: this.state.atype.color,
                                zIndex: 9999, bottom: 10, position: 'fixed', display: "block",
                                left: this.state.atype.left,
                                fontSize: 16, height: 32, width: 100
                            }
                        }>预警类别</a>
                    <div id="txmap" style={{ width: "100%", height: this.state.mapHeight }}>
                  


                    </div>

                </div>

            </>
        );
    }
}