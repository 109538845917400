import React, { Component } from 'react';
import { Route } from 'react-router';
import wx from 'weixin-js-sdk';
import { Layout, LayoutPanel, ButtonGroup, LinkButton, SearchBox, Panel } from 'rc-easyui';
import { Utils } from "../Vector/Utils";
import { DateUtils } from "../Vector/DateUtils";
import { warnContent } from './warnContent';
import '../custom.css';
import './warnlist.css';
import Script from 'react-load-script';

export class LocalWarnList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: '',
            city: '',
            district: '',
            address: '',
            adcode: '',
            latitude: '',
            longitude: '',
            listcont: 0,
            warndata: [],
            tableRowData: []
        }
        this.appid = undefined;
        this.jsapi_ticket = undefined;
        this.noncestr = undefined;
        this.timestamp = undefined;
        this.signature = undefined;
    }

    componentDidMount() {
        document.title = "本地预警";
        this.configJSSDK();
        
        //this.populateWarnData();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        // 基准大小
        var baseSize = 32;
        // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
        var scale = document.documentElement.clientWidth / 750;
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';

    }

    configJSSDK() {
        let that = this;
        let tempData = {
            url: "http://www.vectorsoft.com.cn/localwarn"
        };
        Utils.DoFetch("/Warn/getJsConfig", JSON.stringify(tempData), (result) => {
            that.appid = result.appid;
            that.jsapi_ticket = result.jsapi_ticket;
            that.noncestr = result.noncestr;
            that.timestamp = result.timestamp;
            that.signature = result.signature;

            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: that.appid, // 必填，公众号的唯一标识
                timestamp: that.timestamp, // 必填，生成签名的时间戳
                nonceStr: that.noncestr, // 必填，生成签名的随机串
                signature: that.signature,// 必填，签名
                jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
            });

            wx.ready(function () {
                wx.getLocation({
                    success: function (res) {
                        console.log(res);
                        let latitude = res.latitude;
                        let longitude = res.longitude;
                        let speed = res.speed;
                        that.getLocal(latitude, longitude);
                    },
                    cancel: function (res) {
                        console.log('用户拒绝授权获取地理位置' + res);
                    }
                });
            });

            //config验证失败
            wx.error(function (res) {
                console.log("获取凭据失败" + res);
            });

        });

    }

    getLocal(latitude, longitude) {
        let vm = this;
        let AMap = window.AMap;
        let geocoder = new AMap.Geocoder({
            city: "全国", //城市设为北京，默认：“全国”
            radius: 1000 //范围，默认：500
        });
        let lnglat = [longitude, latitude];
        geocoder.getAddress(lnglat, function (status, result) {
            if (status === 'complete' && result.regeocode) {
                var address = result.regeocode.formattedAddress;
                let province = result.regeocode.addressComponent.province;
                let city = result.regeocode.addressComponent.city;
                let district = result.regeocode.addressComponent.district;
                let adcode = result.regeocode.addressComponent.adcode;
                if (adcode.substring(0, 2) == '53') {
                    let tempData = {
                        //senderCode:adcode.substring(0,4)+'00000000',//获取市级预警
                        senderCode: adcode + '000000',//预期县级预警
                        severity: '',
                        eventType: ''
                    };
                    Utils.DoFetch("/MiniProgram/QueryWarnData", JSON.stringify(tempData), (result) => {
                        //赋值
                        let warndata = result.rows;

                        vm.setState({
                            warndata: warndata,
                            province: province,
                            city: city,
                            district: district,
                            address: address,
                            latitude: latitude,
                            longitude: longitude,
                            adcode: adcode,
                            listcont: result.total
                        });
                    });

                }
                else {
                    vm.setState({
                        province: province,
                        city: city,
                        district: district,
                        address: address,
                        latitude: latitude,
                        longitude: longitude,
                        adcode: adcode
                    });
                }
                //console.log(result.regeocode);
            } else {
               // console.log('根据经纬度查询地址失败');
                vm.setState({ address: "根据经纬度查询地址失败" });
            }
        });

        
    }

    formatDate(v) {

        let vt = Date.parse(v);
        let strDv = "-";
        if (vt > 0) {
            let dv = new Date(vt);
            strDv = DateUtils.FormatDate("YYYY-mm-dd HH:MM", dv);
        }
        return (<span>{strDv}</span>);
    }


    render() {

        return (
            <>
                <Script
                    url="https://webapi.amap.com/maps?v=1.4.15&key=ccdd19a82b5790aa601aa31ede8e5444&plugin=AMap.Geocoder"
                />
                <Layout style={{ width: "100%", height: "100%" }}>
                    <Route path='/warncontent' component={warnContent} />
                    <LayoutPanel border={false} region="north" style={{ height: 100, width: "100%" }}>
                        <Layout style={{ width: "100%", height: "100%" }}>
                            <LayoutPanel border={false} region="north" style={{ height: 50, width: "100%", background: "#f2f2f3", textAlign: "center" }}>
                                <img style={{ height: 18, width: 18, margin: "0 0 -2px 0" }} src={process.env.PUBLIC_URL + '/img/icon_wz_bdyj.png'} />
                                <label style={{ fontSize: 18, color: "#333333", lineHeight: "50px", fontFamily: "PingFangSC-Semibold, sans-serif", fontWeight: "bold", display: "contents" }}>{this.state.address}</label>
                            </LayoutPanel>
                            <LayoutPanel border={false} region="center" style={{ height: 50, width: "100%", margin: "0 auto", background: "#fff", boxSizing: "border-box" }}>
                                <div style={{ height: 50, width: "90%", background: "#fff", margin: "0 auto" }}>
                                    <img style={{ height: 21, width: 21, margin: "0 0 -4px 0" }} src={process.env.PUBLIC_URL + '/img/icon_bdyj_yj.png'} />
                                    <label style={{ fontSize: 16, color: "#404040", lineHeight: "50px", fontFamily: "PingFangSC-Semibold, sans-serif", fontWeight: "bold", display: "contents" }}>预警  </label>
                                    <label style={{ fontSize: 14, color: "#999999", fontFamily: "PingFangSC-Semibold, sans-serif", display: "contents" }}>{this.state.city}{ this.state.district }</label>
                                </div>
                            </LayoutPanel>
                        </Layout>
                    </LayoutPanel>
                    <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                        <Layout style={{ width: "100%", height: "100%" }}>
                            <LayoutPanel border={false} region="north" style={{ height: 2 }}>
                                <div className="split"></div>
                            </LayoutPanel>
                            <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                                {
                                    this.state.warndata.length > 0 ?
                                        <div>
                                            {
                                                this.state.warndata.map(warn => {
                                                    return (
                                                        <div>
                                                            <div onClick={() => this.divWarnListItemHandleClick(warn)} class='alarmTitle' id={'alarm_' + warn.Id} style={{ paddingLeft: '10px' }}>
                                                                <table align='left' height='50px' >
                                                                    <tr>
                                                                        <td style={{ paddingRight: '0px', width: '70px' }} rowSpan='2'><img src={process.env.PUBLIC_URL + '/img/warnlist/symbol/' + warn.EVENTTYPE + '_' + warn.SEVERITY + '.png'} height='60px' width='60px' /></td>
                                                                        <td style={{ fontSize: '15px', fontWeight: 600, color: '#333', paddingLeft: '0px' }}>{warn.HEADLINE}</td>
                                                                    </tr>
                                                                    <tr >
                                                                        <td style={{ color: '#A9A9A9', fontSize: '10px', fontWeight: 100 }} >{this.formatDate(warn.SENDTIME)}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div style={{ width: "90%", borderBottom: '1px #cdcdcd solid', marginTop: '10px', marginLeft: "5px" }}></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div class='currentAlarmMsg1'><img src={process.env.PUBLIC_URL + '/img/warnlist/symbol/dqwyj.png'} /></div>
                                }
                            </LayoutPanel>
                        </Layout>
                    </LayoutPanel>
                </Layout>
            </>
        );
    }
}