import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { DateUtils } from "../Vector/DateUtils";
import './warncontent.css';

export class KepuContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {
                title: "标题",
                addTime: "2000-1-1 1:0:0",
                zhaiyao: "摘要",
                contents: "<p>内容</p>"
            }
        };
        if (this.props.location.query == undefined) {
            this.props.location.query = {
                article: {
                    title: "标题",
                    addTime: "2000-1-1 1:0:0",
                    zhaiyao: "摘要",
                    contents: "<p>内容</p>"
                }
            };
            let param = this.props.location.search;
            param = param.replace("?id=", "");
            this.id = parseInt(param);
        } else {
            this.id = -1;
        }

        this.state = {
            article: this.props.location.query.article
            
        }
    }

    componentDidMount() {
        document.title = "科普详情";
        if (this.id >= 0) {
            this.getArticleById(this.id);
        }
        console.log(this.props.match.params);
    }
    getArticleById(id) {
        let tempData = {
            id: id
        };
        //Utils.DoFetch("/Map/QueryOneWarnData", JSON.stringify(tempData), result => {
        //    this.setState({
        //        article: result.data
        //    });

        //});
    }
    formatDate(v) {
        let vt = Date.parse(v);
        let strDv = "-";
        if (vt > 0) {
            let dv = new Date(vt);
            strDv = DateUtils.FormatDate("YYYY-mm-dd HH:MM", dv);
        }
        return (<span>{strDv}</span>);
    }

    render() {
        let article = this.state.article;
        let cons = article.contents.replace(/\\"/ig, '');//去掉转义的字符
        return (
            <div class="warncontent">
                <div class="boxcontent">
                    <div class="contenttop">                        
                        <div class="warntitle"><p><b class="normal">{article.title}</b></p> <span> {this.formatDate(article.add_time)}</span></div>
                    </div>
                    <div class="content1"><p>摘要：{article.zhaiyao }</p></div>
                    <div class="split"></div>
                    {/*<div class="content1" dangerouslySetInnerHTML={{ __html: article.contents }}></div>*/}
                    <div class="content1" dangerouslySetInnerHTML={{ __html: cons }}></div>                    
                </div>                
            </div>
        )
    }
    
}
