import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout, LayoutPanel, DataGrid, Messager, GridColumn, LinkButton, Panel } from 'rc-easyui';
import { Utils } from "../Vector/Utils";

export class WarnMapTopInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDataGridCls: "icon-arrdown",
            currentInfo:"当前预警信息",
            totalWarnInfo:"共0",
            data: [
                {
                    "warnType": "BLUE", "warnNum": 123, "provinceNum": 12, "cityNum": 44, "countyNum": 11
                },
                {
                    "warnType": "YELLOW", "warnNum": 123, "provinceNum": 12, "cityNum": 44, "countyNum": 11
                },
                {
                    "warnType": "ORANGE", "warnNum": 123, "provinceNum": 12, "cityNum": 44, "countyNum": 11
                },
                {
                    "warnType": "RED", "warnNum": 123, "provinceNum": 12, "cityNum": 44, "countyNum": 11
                },
            ]
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        let tempData = {};
        Utils.DoFetch("/Map/QueryCurrentTotalWarnData", JSON.stringify(tempData), result => {
            this.setState({
                data: result.data,
                currentInfo: "当前生效预警：" + result.total + "条，省级预警：" + result.provNum + "条",
                totalWarnInfo: "共" + result.total
            });
        });
    }
    handleDataView() {
        if (this.state.viewDataGridCls == "icon-arrup") {
            this.setState({
                viewDataGridCls: "icon-arrdown"
            });
        } else {
            this.setState({
                viewDataGridCls: "icon-arrup"
            });
        }
    }
    getLeveInfo(row) {
        switch (row.warnType) {
            case "BLUE":
                return (
                    <div className="f-row">
                        <div style={{ backgroundColor: "blue", width: 5, height: "100%" }}>&nbsp;</div>
                        <span>蓝</span>
                        <span>{row.warnNum}</span>
                    </div>
                );
            case "YELLOW":
                return (
                    <div className="f-row">
                        <div style={{ backgroundColor: "yellow", width: 5, height: "100%" }}>&nbsp;</div>
                        <span>黄</span>
                        <span>{row.warnNum}</span>
                    </div>
                );
            case "RED":
                return (
                    <div className="f-row">
                        <div style={{ backgroundColor: "red", width: 5, height: "100%" }}>&nbsp;</div>
                        <span>红</span>
                        <span>{row.warnNum}</span>
                    </div>
                );
            case "ORANGE":
                return (
                    <div className="f-row">
                        <div style={{ backgroundColor: "orange", width: 5, height: "100%" }}>&nbsp;</div>
                        <span>橙</span>
                        <span>{row.warnNum}</span>
                    </div>
                );
            default:
                return (
                    <div></div>
                    );
        }
    }
    render() {
        return (
            <>
                <div className="f-row" style={{ height: 32 }}>
                    <div className="f-full panel-header f-noshrink panel-header-noborder window-header" style={{ fontSize: 14, height: 32 }}>
                        {this.state.currentInfo}</div>
                    <LinkButton onClick={() => this.handleDataView()} iconCls={this.state.viewDataGridCls} ></LinkButton>

                </div>
                <div style={{ zIndex: 9999, position: "fixed", width: "100%", left: 0, top: 32 }}>
                    {
                        this.state.viewDataGridCls == "icon-arrup" ?
                            <DataGrid data={this.state.data} >
                                <GridColumn field="warnType" title={this.state.totalWarnInfo} width={80}
                                    render={({ row }) => (
                                        this.getLeveInfo(row)
                                    )}
                                />
                                <GridColumn field="warnNum" title="" width={0}
                                    render={({ row }) => (
                                        null
                                    )}
                                />
                                <GridColumn field="provinceNum" title="省级" />
                                <GridColumn field="cityNum" title="市级" />
                                <GridColumn field="countyNum" title="县级" />
                            </DataGrid> :
                            <div></div>
                    }
                </div>
            </>

        );
    }
}