import React, { Component } from 'react';
import { DateUtils } from "../Vector/DateUtils";
import { Utils } from '../Vector/Utils';
import './warncontent.css';

export class warnContent extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.query == undefined) {
            this.props.location.query = {
                warn: {
                    EVENTTYPE: null,
                    SEVERITY: null,
                    HEADLINE: null,
                    SENDTIME: null,
                    DESCRIPTION: null
                }
            };
            let param = this.props.location.search;
            param = param.replace("?id=", "");
            this.id = parseInt(param);
            
        } else {
            this.id = -1;
        }
       
        this.state = {
            warn: this.props.location.query.warn,
            guideContent: null
        }
        
    }
    getWarnById(id) {
        let tempData = {
            id: id
        };
        Utils.DoFetch("/Map/QueryOneWarnData", JSON.stringify(tempData), result => {
            this.setState({
                warn: result.data
            });
            
        });
    }
    componentWillMount() {
       
    }
    componentDidMount() {
        document.title = "预警详情";
        if (this.id >= 0) {
            this.getWarnById(this.id);
        }
        //console.log(this.props.match.params);
        //console.log(JSON.stringify(this.props.match));
    }
    
    
    formatDate(v) {

        let vt = Date.parse(v);
        let strDv = "-";
        if (vt > 0) {
            let dv = new Date(vt);
            strDv = DateUtils.FormatDate("YYYY-mm-dd HH:MM", dv);
        }
        return (<span>{strDv}</span>);
    }

    render() {
        let warnMsg = this.state.warn;
        let expTime = Date.parse(warnMsg.EXPIRES);
        var currentTime = new Date();
        return (
            expTime < currentTime ?
                <div style={{ textAlign: "center" }}>
                    <p style={{ fontSize: ".2rem" }}>预警已解除</p>
                </div>
                :
            <div class="warncontent">
                <div class="boxcontent">
                    <div class="contenttop">
                        <div class="warnimg"><img src={process.env.PUBLIC_URL + '/img/warnlist/symbol/' + warnMsg.EVENTTYPE + '_' + warnMsg.SEVERITY + '.png'} alt='test' onerror={"this.src='" + process.env.PUBLIC_URL + "/img/warnlist/unknow_y.png'"} /></div>
                        <div class="warntitle"><p><b class="normal">{warnMsg.HEADLINE}</b></p> <span> {this.formatDate(warnMsg.SENDTIME)}</span></div>
                    </div>
                        <div class="content1"><p>{warnMsg.DESCRIPTION}</p></div>
                    <div class="split"></div>

                    <div class="guide" id="nodiv" >
                        <div class="guidetitle"><p><h4>防御指南</h4></p></div>
                            <div class="guidecontent"><p>{warnMsg.DEFENSE}</p>
                        </div>
                    </div>
                </div>

                <div class="split"></div>

                <div class="warnpublish"   >
                    <div class="publish" >
                        <div class="publishtitle">
                            <p><h4>预警信息来源</h4></p>
                        </div>
                        <div class="publishtop">
                            <div class="publishimg"><img src={process.env.PUBLIC_URL + '/img/warnlist/unknow_y.png'} /></div>
                            <div class="publishsender"><p> 云南地质灾害气象风险预警 </p>  </div>
                        </div>
                        <div style={{width: '6.225rem',margin: '0 auto',height: 'auto',paddingBottom: '0.6rem'}}>
                            <div style={{ width: '2.4rem', margin: '0 auto' }}>
                                <img style={{ width: '2.4rem'}} src={process.env.PUBLIC_URL + '/img/warnlist/code.png'} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}