import React, { Component } from 'react';
import {
    CheckBox, Label, RadioButton, Messager
} from 'rc-easyui';
import '../custom.css';
import { Utils } from '../Vector/Utils';

export class ReceiveSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admLevels: [
                { admLevel: ReceiveSet.AdmLevel_Province, admName: "省" },
                { admLevel: ReceiveSet.AdmLevel_City, admName: "市" },
                { admLevel: ReceiveSet.AdmLevel_County, admName: "县" }
                ],
           
            recvTimes: [
                { id: ReceiveSet.Recv_All, text: "开启", info: "全时段接受预警推送" },
                { id: ReceiveSet.Recv_NotNight, text: "夜间不推送", info: "夜间（晚23点到早5点）接受预警推送" },
                { id: ReceiveSet.Recv_Closed, text: "关闭", info: "全时段关闭预警推送" },
            ],
            user: {
                openid: "",
                nickname: "",
                headimgurl: ""
            },
            severityData: [                
                { "ServerityName": "蓝色", "ServerityCode": "BLUE" },
                { "ServerityName": "黄色", "ServerityCode": "YELLOW" },
                { "ServerityName": "橙色", "ServerityCode": "ORANGE" },
                { "ServerityName": "红色", "ServerityCode": "RED" }
            ],
            recvSet: {
                admLevels: [ReceiveSet.AdmLevel_City],
                severities: ["RED"],
                timeId: ReceiveSet.Recv_NotNight
            },
            timeInfo:""
        };
        let param = this.props.location.search;
        let openid = "";
        if (param !== "") {
            openid = param.replace("?oid=", "");
        }
        this.getWXUserInfo(openid);
    }
    static AdmLevel_Province = 2;
    static AdmLevel_City = 3;//地级市
    static AdmLevel_County = 4;//县级市
    static Recv_All = 1;
    static Recv_NotNight = 2;
    static Recv_Closed = 3;
    componentDidMount() {
        document.title = "接收设置";
    }
    getWXUserInfo(oid) {
        //alert(oid);
        let data = {
            openid: oid
        };
        //Utils.DoFetch("/Mem/Test", JSON.stringify(data), result => {
        //    alert(result.value);
        //});
        Utils.DoFetch("/UC/QueryCurrentWXOperInfo", JSON.stringify(data), result => {
            let timeInfo = "";
            this.state.recvTimes.forEach((v) => {
                if (v.id == result.recvSet.timeId) {
                    timeInfo = v.info
                }
            });
            this.setState({
                user: result.user,
                cityData: result.cityData,
                recvSet: result.recvSet,
                timeInfo: timeInfo
            });
        });
    }
    handleTimeIdChange(timeId, checked) {
        let recvSet = this.state.recvSet;
        
        if (checked) {
            recvSet.timeId = timeId;
            let timeInfo = "";
            this.state.recvTimes.forEach((v) => {
                if (v.id == timeId) {
                    timeInfo = v.info
                }
            });
            this.saveRecieveSet(recvSet);
            this.setState({
                recvSet: recvSet,
                timeInfo: timeInfo
            });
        }
    }
    handleSeverityChanged(serverityCode, checked) {
        let recvSet = this.state.recvSet;

        if (checked) {
            if (recvSet.severities.indexOf(serverityCode) < 0) {
                recvSet.severities.push(serverityCode);
            }
        } else {
            let index = recvSet.severities.indexOf(serverityCode);
            if (index >= 0) {
                recvSet.severities.splice(index, 1);
            }
        }
        this.saveRecieveSet(recvSet);
        this.setState({
            recvSet: recvSet
        });
    }
    handleAdmLevelChanged(admLevel, checked) {
        let recvSet = this.state.recvSet;

        if (checked) {
            if (recvSet.admLevels.indexOf(admLevel) < 0) {
                recvSet.admLevels.push(admLevel);
            }
        } else {
            let index = recvSet.admLevels.indexOf(admLevel);
            if (index >= 0) {
                recvSet.admLevels.splice(index, 1);
            }
        }
        this.saveRecieveSet(recvSet);
        this.setState({
            recvSet: recvSet
        });
    }
    saveRecieveSet(recvSetValue) {
        let saveData = {
            openid: this.state.user.openid,
            recvSet: recvSetValue
        }
        Utils.DoFetch("UC/SaveCurrentEXOperRecvSetInfo", JSON.stringify(saveData), result => {
            if (result.result != 1) {
                this.msgAlert.alert({
                    title: "提示",
                    icon: "error",
                    msg: result.info
                });
            }
        });
    }
    render() {
        return (
            <div className="f-full" style={{ width: "100%", margin: 5 }}>
                <div className="f-row" style={{ margin:28 }}>
                    <img style={{ borderRadius: "100%", width: 140 }} src={this.state.user.headimgurl} alt="" />
                    <span style={{ width: "70%", height: 15, marginTop: 60, marginLeft:20 }}> {this.state.user.nickname} </span>
                </div>
                <div class="line"></div>

                <div className="f-row" style={{ width: "100%",fontSize:24 }}>接收预警发布单位</div>
                <div className="f-row" style={{ width: "100%", fontSize: 18 }}>{
                    this.state.admLevels.map(admLevel => {
                        return (
                            <div key={admLevel.admLevel}>
                                <CheckBox
                                    inputId={admLevel.admName}
                                    value={admLevel.admLevel}
                                    checked={this.state.recvSet.admLevels.indexOf(admLevel.admLevel) >= 0}
                                    onChange={(checked) => this.handleAdmLevelChanged(admLevel.admLevel, checked)}
                                >
                                </CheckBox>
                                <Label htmlFor={admLevel.admName} style={{ margin: '0 5px' }}>{admLevel.admName}</Label>
                            </div>
                        )
                    })}
                </div>

                <div className="f-row" style={{ width: "100%",fontSize: 24 }}>接收预警等级</div>
                <div className="f-row" style={{ width: "100%", fontSize: 18 }}>
                    {
                        this.state.severityData.map(severity => {
                            return (
                                <div key={severity.ServerityCode}>
                                    <CheckBox
                                        inputId={severity.ServerityCode}
                                        value={severity.ServerityCode}
                                        checked={this.state.recvSet.severities.indexOf(severity.ServerityCode)>=0}
                                        onChange={(checked) => this.handleSeverityChanged(severity.ServerityCode, checked)}
                                    >
                                    </CheckBox>
                                    <Label htmlFor={severity.ServerityCode} style={{ margin: '0 3px' }}>{severity.ServerityName}</Label>
                                </div>
                            )
                        })}
                </div>
                <div className="f-row" style={{ width: "100%",fontSize: 24 }}>接收预警时段</div>
                <div className="f-row" style={{ width: "100%" ,fontSize: 18 }}>
                    {
                        this.state.recvTimes.map(times => {
                            return (
                                <div key={times.id}>
                                    <RadioButton
                                        inputId={times.id}
                                        value={times.id}
                                        groupValue={this.state.recvSet.timeId}
                                        onChange={(checked) => this.handleTimeIdChange(times.id, checked)}
                                    >
                                    </RadioButton>
                                    <Label htmlFor={times.id} style={{ margin: '0 5px', width:'auto' }}>{times.text}</Label>
                                </div>
                            )
                        })}
                </div>
                <div className="f-row">
                    <Label style={{ margin: '0 5px', width: 'auto', fontSize: 16 }}>{this.state.timeInfo}</Label>
                </div>
                <div class="line"></div>
                < Messager ref={ref => this.msgAlert = ref} ></Messager >
            </div>
        );

    }

}