export class DateUtils {
    // 增加天 
    static AddDays(date, value) {
        date.setDate(date.getDate() + value);
        return date;
    }

    // 增加月 
    static AddMonths(date, value) {
        date.setMonth(date.getMonth() + value);
        return date;
    }

    // 增加年 
    static AddYears(date, value) {
        date.setFullYear(date.getFullYear() + value);
        return date;
    }

    // 是否为今天 
    static IsToday(date) {
        return DateUtils.IsDateEquals(date, new Date());

    }

    // 是否为当月 
    static IsThisMonth(date) {
        return DateUtils.IsMonthEquals(date, new Date());
    }

    // 两个日期的年是否相等 
    static IsMonthEquals(date1, date2) {
        return date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear();
    }

    // 判断日期是否相等 
    static IsDateEquals(date1, date2) {
        return date1.getDate() == date2.getDate() && DateUtils.IsMonthEquals(date1, date2);
    }

    // 返回某个日期对应的月份的天数 
    static GetMonthDayCount(date) {
        switch (date.getMonth() + 1) {
            case 1: case 3: case 5: case 7: case 8: case 10: case 12:
                return 31;
            case 4: case 6: case 9: case 11:
                return 30;
        }
        //feb: 
        date = new Date(date);
        var lastd = 28;
        date.setDate(29);
        while (date.getMonth() == 1) {
            lastd++;
            DateUtils.AddDays(date, 1);
        }
        return lastd;
    }

    // 返回两位数的年份 
    static GetHarfYear(date) {
        var v = date.getYear();
        if (v > 9) return v.toString();
        return "0" + v;
    }

    // 返回月份（修正为两位数） 
    static GetFullMonth(date) {
        var v = date.getMonth() + 1;
        if (v > 9) return v.toString();
        return "0" + v;
    }

    // 返回日 （修正为两位数） 
    static GetFullDate(date) {
        var v = date.getDate();
        if (v > 9) return v.toString();
        return "0" + v;
    }

    // 替换字符串 
    static Replace(str, from, to) {
        return str.split(from).join(to);
    }

   

    // 统一日期格式 
    static ConvertDate(str) {
        str = (str + "").replace(/^\s*/g, "").replace(/\s*$/g, ""); // 去除前后的空白 
        var d;
        if (/^[0-9]{8}$/.test(str)) // 20040226 -> 2004-02-26 
        {
            d = new Date(new Number(str.substr(0, 4)), new Number(str.substr(4, 2)) - 1, new Number(str.substr(6, 2)));
            if (d.getTime()) return d;
        }
        d = new Date(str);
        if (d.getTime()) return d;
        d = new Date(DateUtils.Replace(str, "-", "/"));
        if (d.getTime()) return d;
        return null;
    }

    static FormatDate(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }
    
}