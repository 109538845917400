import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout, LayoutPanel, ButtonGroup, LinkButton, SearchBox, Panel} from 'rc-easyui';
import { Utils } from "../Vector/Utils";
import { DateUtils } from "../Vector/DateUtils";
import { warnContent } from './warnContent';
import '../custom.css';
import './warnlist.css';

export class WarnList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchingValue: {
                value: null,
                category: null
            },
            categories: [
                { value: 'all', text: '全部', iconCls: 'icon-filter' },
                { value: 'event', text: '预警种类', iconCls: 'icon-filter' },
                { value: 'severity', text: '预警等级', iconCls: 'icon-filter' },
                { value: 'sender', text: '发布单位', iconCls: 'icon-filter' }
            ],
            senderCode: "",
            severity: "",
            eventType: "",
            listcont: 0,
            warndata: [],
            senderdata: [],
            severitydata: [],
            eventdata: [],
            eventicon: "more",
            severityicon: "more",
            sendericon: "more",
            eventdispaly: true,
            severitydispaly: true,
            senderdispaly: true,
            eventname: "预警种类",
            severityname: "预警等级",
            sendername: "发布单位",
            tableRowData: []
        };
        this.populateWarnData();
    }

    componentDidMount() {
        document.title = "预警列表";

        window.addEventListener('resize', this.handleResize);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        // 基准大小
        var baseSize = 32;
        // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
        var scale = document.documentElement.clientWidth / 750;
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';

    }

    divWarnListItemHandleClick(warnitem) {
        //alert(id);
        this.props.history.push({ pathname: '/warncontent', query: { warn: warnitem } });
    }

    onTypeHandleClick(type) {

        switch (type) {
            case "event":
                this.setState({ senderdispaly: true, sendericon: "more", severitydispaly: true, severityicon: "more" });
                if (this.state.eventicon == "more") {
                    this.setState({ eventicon: "fan", eventdispaly: false });
                }
                else {
                    this.setState({ eventicon: "more", eventdispaly: true });
                }
                break;
            case "severity":
                this.setState({ senderdispaly: true, sendericon: "more", eventdispaly: true, eventicon: "more" });
                if (this.state.severityicon == "more") {
                    this.setState({ severityicon: "fan", severitydispaly: false });
                }
                else {
                    ;
                    this.setState({ severityicon: "more", severitydispaly: true });
                }
                break;
            case "sender":
                this.setState({ eventdispaly: true, eventicon: "more", severitydispaly: true, severityicon: "more" });
                if (this.state.sendericon == "more") {
                    this.setState({ sendericon: "fan", senderdispaly: false });
                }
                else {
                    this.setState({ sendericon: "more", senderdispaly: true });
                }
                break;
            default:
                return null;
        }
    }

    onTypeRowsHandleClick(type, eventtype, eventname, severitytype, severityname, sendertype, sendername) {
        this.setState({ eventdispaly: true, eventicon: "more", senderdispaly: true, sendericon: "more", severitydispaly: true, severityicon: "more" })
        switch (type) {
            case "event":
                if (eventtype == "") {
                    this.setState({ eventname: "预警种类", eventType: eventtype });
                }
                else {
                    this.setState({ eventname: eventname, eventType: eventtype });
                }
                break;
            case "severity":
                if (severitytype == "") {
                    this.setState({ severityname: "预警等级", severity: severitytype });
                }
                else {
                    this.setState({ severityname: severityname, severity: severitytype });
                }
                break;
            case "sender":
                if (sendertype == "") {
                    this.setState({ sendername: "发布单位", senderCode: sendertype });
                }
                else {
                    this.setState({ sendername: sendername, senderCode: sendertype });
                }
                break;
            default:
                return null;
        }
        let tempData = {
            senderCode: sendertype,
            severity: severitytype,
            eventType: eventtype
        };
        Utils.DoFetch("/Warn/QueryWarnData", JSON.stringify(tempData), (result) => {
            this.setState({ listcont: result.total, warndata: result.rows, senderdata: result.sender, severitydata: result.severity, eventdata: result.event });
        });
        // this.forceUpdate();
    }

    formatDate(v) {

        let vt = Date.parse(v);
        let strDv = "-";
        if (vt > 0) {
            let dv = new Date(vt);
            strDv = DateUtils.FormatDate("YYYY-mm-dd HH:MM", dv);
        }
        return (<span>{strDv}</span>);
    }

    populateWarnData() {
        let tempData = {
            senderCode: this.state.senderCode,
            severity: this.state.severity,
            eventType: this.state.eventType
        };
        Utils.DoFetch("/Warn/QueryWarnData", JSON.stringify(tempData), (result) => {
            this.setState({ listcont: result.total, warndata: result.rows, senderdata: result.sender, severitydata: result.severity, eventdata: result.event });
        });
    }

    handleSearch(searchingValue) {
        let tempData;
        switch (searchingValue.category) {
            case "all":
                tempData = {
                    senderCode: searchingValue.value,
                    severity: searchingValue.value,
                    eventType: searchingValue.value
                };
                break;
            case "event":
                tempData = {
                    senderCode: "",
                    severity: "",
                    eventType: searchingValue.value
                };
                break;
            case "severity":
                tempData = {
                    senderCode: "",
                    severity: searchingValue.value,
                    eventType: ""
                };
                break;
            case "sender":
                tempData = {
                    senderCode: searchingValue.value,
                    severity: "",
                    eventType: ""
                };
                break;
            default:
                return null;
        }
        Utils.DoFetch("/Warn/LikeQueryWarnData", JSON.stringify(tempData), (result) => {
            this.setState({ listcont: result.total, warndata: result.rows, senderdata: result.sender, severitydata: result.severity, eventdata: result.event });
        });
        this.setState({ searchingValue: searchingValue });
    }
    handleClear() {
        const value = Object.assign({}, this.state.searchingValue, { value: null });
        this.setState({ searchingValue: value });
    }
    render() {
        let warnTypeEventRows = [];
        warnTypeEventRows.push(<div class="boxdiv">
            <p onClick={() => this.onTypeRowsHandleClick("event", "", "全部", "", "", "", "")}
                style={{ color: '#1d347f', fontWeight: 'bold', cursor: 'pointer', marginBottom: 0 }} id="typeevent" data="全部"   > 全部(
                <span id="all_event">{this.state.warndata.length} </span>
                )
                </p>
        </div>
        );
        this.state.eventdata.map((item, index) => {
            warnTypeEventRows.push(<div class='boxdiv'> <p onClick={() => this.onTypeRowsHandleClick("event", item.type, item.name, "", "", "", "")} id='typeevent' data={item.name}>{item.name} ({item.count}) </p> </div>);
        });
        let warnTypeSeverityRows = [];
        warnTypeSeverityRows.push(<div class="boxdiv"><p onClick={() => this.onTypeRowsHandleClick("severity", "", "", "", "全部", "", "")} style={{ color: '#1d347f', fontWeight: 'bold', cursor: 'pointer', marginBottom: 0 }} id="typeseverity" data="全部"   > 全部(<span id="all_severity">{this.state.warndata.length} </span>) </p> </div>);
        this.state.severitydata.map((item, index) => {
            warnTypeSeverityRows.push(<div class='boxdiv'> <p onClick={() => this.onTypeRowsHandleClick("severity", "", "", item.type, item.name, "", "")} id='typeseverity' data={item.name}>{item.name} ({item.count}) </p> </div>);
        });
        let warnTypeSenderRows = [];
        warnTypeSenderRows.push(<div class="boxdiv"><p onClick={() => this.onTypeRowsHandleClick("sender", "", "", "", "", "", "全部")} style={{ color: '#1d347f', fontWeight: 'bold', cursor: 'pointer', marginBottom: 0 }} id="typesender" data="全部"   > 全部(<span id="all_sender">{this.state.warndata.length} </span>) </p> </div>);
        this.state.senderdata.map((item, index) => {
            warnTypeSenderRows.push(<div class='boxdiv'> <p onClick={() => this.onTypeRowsHandleClick("sender", "", "", "", "", item.code, item.name)} id='typesender' data={item.name}>{item.name} ({item.count}) </p> </div>);
        });
        return (
            <Layout style={{ width: "100%", height: "100%" }}>
                <Route path='/warncontent' component={warnContent} />
                <LayoutPanel border={false} region="north" style={{ height: 40 }}>
                    <ButtonGroup style={{ width: "100%", height: "100%" }}>
                        <LinkButton iconCls={this.state.eventicon == "more" ? "icon-arrdown" : "icon-arrup"} onClick={() => this.onTypeHandleClick("event")} iconAlign="right" style={{ width: "33%", borderColor: "transparent" }}>{this.state.eventname}</LinkButton>
                        <LinkButton iconCls={this.state.severityicon == "more" ? "icon-arrdown" : "icon-arrup"} onClick={() => this.onTypeHandleClick("severity")} iconAlign="right" style={{ width: "33%", borderColor: "transparent" }}>{this.state.severityname}</LinkButton>
                        <LinkButton iconCls={this.state.sendericon == "more" ? "icon-arrdown" : "icon-arrup"} onClick={() => this.onTypeHandleClick("sender")} iconAlign="right" style={{ width: "34%", borderColor: "transparent" }}>{this.state.sendername}</LinkButton>
                    </ButtonGroup>
                </LayoutPanel>
                <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                    <Layout style={{ width: "100%", height: "100%" }}>
                        <LayoutPanel border={false} region="north" style={{ height: 20 }}>
                            <div className="split"></div>
                        </LayoutPanel>

                        <Panel border={false} bodyCls="warnType" ref="warnTypeEvent" closed={this.state.eventdispaly}>
                            {warnTypeEventRows}
                        </Panel>
                        <Panel border={false} bodyCls="warnType" ref="warnTypeSeverity" closed={this.state.severitydispaly}>
                            {warnTypeSeverityRows}
                        </Panel>
                        <Panel border={false} bodyCls="warnType" ref="warnTypeSender" closed={this.state.senderdispaly}>
                            {warnTypeSenderRows}
                        </Panel>
                        <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                            <Layout style={{ width: "100%", height: "100%" }}>
                                <LayoutPanel border={false} region="north" style={{ height: 40 }}>
                                    <SearchBox
                                        style={{ width: "95%" }}
                                        placeholder="搜索预警"
                                        value={this.state.searchingValue.value}
                                        category={this.state.searchingValue.category}
                                        categories={this.state.categories}
                                        onSearch={this.handleSearch.bind(this)}
                                        addonRight={() => (
                                            <span className="textbox-icon icon-clear" title="Clear value" onClick={this.handleClear.bind(this)}></span>
                                        )}
                                    />
                                </LayoutPanel>
                                <LayoutPanel border={false} region="center" style={{ width: "100%", height: "100%" }}>
                                    {
                                        this.state.warndata.length > 0 ?
                                            <div>
                                                {
                                                    this.state.warndata.map(warn => {
                                                        return (
                                                            <div>
                                                                <div onClick={() => this.divWarnListItemHandleClick(warn)} class='alarmTitle' id={'alarm_' + warn.Id} style={{ paddingLeft: '10px' }}>
                                                                    <table align='left' height='50px' >
                                                                        <tr>
                                                                            <td style={{ paddingRight: '0px', width: '70px' }} rowSpan='2'><img src={process.env.PUBLIC_URL + '/img/warnlist/symbol/' + warn.EVENTTYPE + '_' + warn.SEVERITY + '.png'} height='60px' width='60px' /></td>
                                                                            <td style={{ fontSize: '15px', fontWeight: 600, color: '#333', paddingLeft: '0px' }}>{warn.HEADLINE}</td>
                                                                        </tr>
                                                                        <tr >
                                                                            <td style={{ color: '#A9A9A9', fontSize: '10px', fontWeight: 100 }} >{this.formatDate(warn.SENDTIME)}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div style={{ width: "90%", borderBottom: '1px #cdcdcd solid', marginTop: '10px', marginLeft: "5px" }}></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div class='currentAlarmMsg1'><img src={process.env.PUBLIC_URL + '/img/warnlist/symbol/dqwyj.png'} /></div>
                                    }
                                </LayoutPanel>
                            </Layout>
                        </LayoutPanel>
                    </Layout>
                </LayoutPanel>
            </Layout>
        );
    }
}