import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import zhcn from 'rc-easyui/dist/locale/easyui-lang-zh_CN'
import { LocaleProvider } from 'rc-easyui';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <LocaleProvider locale={zhcn}>
            <App />
        </LocaleProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

