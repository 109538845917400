import React, { Component } from 'react';
import { Route } from 'react-router';
import { WarnList } from './WarnList/WarnList';
import { warnContent } from './WarnList/warnContent';
import { LocalWarnList } from './WarnList/LocalWarnList';
import { KepuList } from './WarnList/KepuList';
import { KepuContent } from './WarnList/KepuContent';
import { ReceiveSet } from './UCenter/ReceiveSet';
import { WarnRss } from './UCenter/WarnRss';
import { WarnMap } from './WarnList/WarnMap';
import { DouYin } from './WarnList/DouYin';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    static displayName = App.name;
    componentDidMount() {
          this.createMune();
    }

    async createMune() {
        const response = await fetch('api/wechat/createmenu');
    }
    render() {
        return (
            <div>
                <Route path='/localwarn' component={LocalWarnList} />
                <Route path='/warnlist' component={WarnList} />
                <Route path='/warncontent' component={warnContent} />
                <Route path='/kepulist' component={KepuList} />
                <Route path='/kepucontent' component={KepuContent} />
                <Route path='/receiveset' component={ReceiveSet} />
                <Route path='/warnrss' component={WarnRss} />
                <Route path='/warnmap' component={WarnMap} />
                <Route path='/douyin' component={DouYin} />
                
            </div>
        );
    }
}
